import { memo } from "react";
import styled from "styled-components/macro";

import { THEME_SPLASH_COLOR } from "../../constants/style_constants";
import Switch from "./toggle_switch";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: start;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

type SelectionProps = {
  selected: boolean;
};
const Selection = styled.div<SelectionProps>`
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  margin-left: 1vw;
  color: ${(props) => (props.selected ? THEME_SPLASH_COLOR : "black")};
`;

function ToggleForm({ title, stateValue, setValue }) {
  const onFormChange = (e) => {
    const value = e.target.checked;
    setValue(value);
  };
  return (
    <Wrapper>
      <Title>{title}</Title>
      <InputWrapper>
        <Switch id={title} toggled={stateValue} onChecked={onFormChange} />
        <Selection selected={stateValue}>{stateValue ? "Yes" : "No"}</Selection>
      </InputWrapper>
    </Wrapper>
  );
}

export default memo(ToggleForm);
