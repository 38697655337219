import { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";

import setAction from "../../actions/setter";
import {
  INPUT_DEVICE_OPTIONS_MAP,
  INPUT_DEVICE_OPTIONS_REVERSE_MAP,
  MICROPHONE_DEVICE,
  MIDI_DEVICE,
  MULTIPLE_CHOICE,
  MULTIPLE_CHOICES_ARE_OF_CHORDS_MAP,
  MULTIPLE_CHOICES_ARE_OF_INTERVALS_MAP,
} from "../../constants/settings_constants";
import { THEME_SPLASH_COLOR } from "../../constants/style_constants";
import { CHORDS, INTERVALS } from "../../reducers/constants";
import { MODAL_STATE_REDUCER } from "../../reducers/modal_state";
import Microphone from "../../utils/microphone";
import MidiDevice from "../../utils/midi";
import { GreenBtn, RedBtn } from "../buttons";
import NumberForm from "../forms/number_form";
import RadioForm from "../forms/radio_form";
import ToggleForm from "../forms/toggle_form";

const ContentWrapper = styled.div`
  min-width: 50vw;
  min-height: 20vh;
  padding: 25px;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PreviewTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: start;
`;
const PreviewSubText = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 1rem;
`;

type NotePreviewProps = {
  previewingNote: boolean;
};
const NotePreview = styled.div<NotePreviewProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.previewingNote ? "3.5rem" : "1rem")};
  color: ${THEME_SPLASH_COLOR};
  font-weight: 600;
  margin-top: 2vh;
`;

let microphone;
let midi;

const NOTE_PREVIEW_TEXT = "Play to preview note...";

type InputOptionsProps = {
  gameType: string;
  inputDeviceOpt: any;
  multipleChoicesAreOfOpt: string;
  setGameOption: any;
  numberOfMultipleChoiceQuestionsOpt?: number;
  showNoteDisplayOpt?: boolean;
  hideMicTest?: boolean;
};
function InputOptions({
  gameType,
  inputDeviceOpt,
  numberOfMultipleChoiceQuestionsOpt,
  multipleChoicesAreOfOpt,
  showNoteDisplayOpt,
  setGameOption,
  hideMicTest,
}: InputOptionsProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isListening, setIsListening] = useState(false);
  const [notesPlayed, setNotesPlayed] = useState(null);
  const [microphoneLoading, setMicrophoneLoading] = useState(false);

  const inputDeviceSelected = INPUT_DEVICE_OPTIONS_MAP[inputDeviceOpt];

  // When input device changes or component unmounts, reset inputs
  const resetInputs = useCallback(() => {
    if (microphone?._init) {
      microphone.destroy();
      microphone = null;
    }
    if (midi?._init) {
      midi.destroy();
      midi = null;
    }
    setIsLoading(true);
  }, []);
  useEffect(() => {
    resetInputs();
  }, [inputDeviceSelected]);
  useEffect(() => {
    return () => {
      resetInputs();
    };
  }, []);

  // Callback used internally by Microphone to set played note
  const onNotePlayed = (note) => {
    setNotesPlayed(note);
  };

  const resetInputDevice = () => {
    setGameOption("inputDeviceOpt")(
      INPUT_DEVICE_OPTIONS_REVERSE_MAP[MULTIPLE_CHOICE]
    );
    midi = null;
  };

  if (inputDeviceSelected === MICROPHONE_DEVICE) {
    // Once micDevice is selected, load microphone or on mic change
    if (!microphone) {
      setMicrophoneLoading(true);
      microphone = new Microphone(
        onNotePlayed,
        (value: any) => setAction(MODAL_STATE_REDUCER, "errorModalData", value),
        setIsListening
      );
      microphone.init().then(() => {
        setMicrophoneLoading(false);
        setIsLoading(false);
      });
    }
  } else if (inputDeviceSelected === MIDI_DEVICE) {
    if (!midi) {
      midi = new MidiDevice(onNotePlayed, resetInputDevice);
      midi.startListening().then((hasMidiDevices) => {
        if (!hasMidiDevices) {
          midi = null;
        } else {
          setIsLoading(false);
        }
      });
    }
  } else if (inputDeviceSelected === MULTIPLE_CHOICE) {
    // Don't need to load for multiple choice
    setIsLoading(false);
  }

  let DeviceOptions = null;
  if (isLoading) {
    // TODO: Add loading spinner
    DeviceOptions = "Loading...";
  } else if (inputDeviceSelected === MICROPHONE_DEVICE) {
    let MicPreviewRender = null;
    if (hideMicTest) {
      MicPreviewRender = (
        <PreviewWrapper>
          <PreviewTitle>Device: {microphone.deviceName}</PreviewTitle>
        </PreviewWrapper>
      );
    } else if (microphoneLoading) {
      MicPreviewRender = "Microphone is loading...";
    } else {
      let NotePreviewRender = null;
      if (isListening) {
        NotePreviewRender = NOTE_PREVIEW_TEXT;
        if (notesPlayed && notesPlayed.length) {
          NotePreviewRender = notesPlayed[0];
        }
      } else {
        NotePreviewRender = "";
      }
      MicPreviewRender = (
        <PreviewWrapper>
          <PreviewTitle>Device: {microphone.deviceName}</PreviewTitle>
          <PreviewSubText>
            {
              "If note doesn't match, try tuning your instrument, trying another device, or switching to Midi."
            }
          </PreviewSubText>
          <NotePreview
            previewingNote={
              NotePreviewRender !== "" &&
              NotePreviewRender !== NOTE_PREVIEW_TEXT
            }
          >
            {NotePreviewRender}
          </NotePreview>
          {!isListening ? (
            <GreenBtn small onClick={() => microphone.listen()}>
              Test Mic
            </GreenBtn>
          ) : (
            <RedBtn small onClick={() => microphone.stop()}>
              Stop Listening
            </RedBtn>
          )}
        </PreviewWrapper>
      );
    }
    DeviceOptions = <>{MicPreviewRender}</>;
  } else if (inputDeviceSelected === MIDI_DEVICE) {
    let NotePreviewRender = NOTE_PREVIEW_TEXT;
    if (notesPlayed && notesPlayed.length) {
      NotePreviewRender = notesPlayed[0];
    }
    DeviceOptions = (
      <>
        <PreviewWrapper>
          <PreviewSubText>Listening on all Midi Devices</PreviewSubText>
          <NotePreview previewingNote={NotePreviewRender !== NOTE_PREVIEW_TEXT}>
            {NotePreviewRender}
          </NotePreview>
        </PreviewWrapper>
      </>
    );
  } else if (inputDeviceSelected === MULTIPLE_CHOICE) {
    let optionsMap;
    if (gameType === INTERVALS) {
      optionsMap = MULTIPLE_CHOICES_ARE_OF_INTERVALS_MAP;
    } else if (gameType === CHORDS) {
      optionsMap = MULTIPLE_CHOICES_ARE_OF_CHORDS_MAP;
    }
    DeviceOptions = (
      <>
        {typeof numberOfMultipleChoiceQuestionsOpt !== "undefined" && (
          <NumberForm
            title="Number of Choices"
            stateValue={numberOfMultipleChoiceQuestionsOpt}
            setValue={setGameOption("numberOfMultipleChoiceQuestionsOpt")}
            min={2}
            max={12}
          />
        )}
        {optionsMap && (
          <RadioForm
            title="Choices are of: "
            optionsMap={optionsMap}
            selectedOption={multipleChoicesAreOfOpt}
            setValue={setGameOption("multipleChoicesAreOfOpt")}
          />
        )}
        {typeof showNoteDisplayOpt !== "undefined" && (
          <ToggleForm
            title="Show Note Display (e.g. Sheet Music)"
            stateValue={showNoteDisplayOpt}
            setValue={setGameOption("showNoteDisplayOpt")}
          />
        )}
      </>
    );
  }
  return (
    <>
      <ContentWrapper>
        <RadioForm
          title="Input Mode"
          optionsMap={INPUT_DEVICE_OPTIONS_MAP}
          selectedOption={inputDeviceOpt}
          setValue={setGameOption("inputDeviceOpt")}
        />
        {DeviceOptions}
      </ContentWrapper>
    </>
  );
}

export default InputOptions;
