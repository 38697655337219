import AsyncRoute from "preact-async-route";
import Router from "preact-router";
import Helmet from "react-helmet";
import { Provider } from "react-redux";

import Loading from "./components/loading";
import ModalContainer from "./components/modal/modal_container";
import store from "./store";

export default function index() {
  return (
    <Provider store={store}>
      {/* @ts-expect-error: Router cannot be used as JSX component */}
      <Router>
        {/* @ts-expect-error: AsyncRoute cannot be used as JSX component */}
        <AsyncRoute
          path="/"
          getComponent={() =>
            import("./components/mode_select").then((module) => module.default)
          }
          loading={() => (
            <>
              <Helmet title="Ear Tools - Flexible App For Learning Music Theory By Ear" />
              <Loading />
            </>
          )}
        />
        {/* @ts-expect-error: AsyncRoute cannot be used as JSX component */}
        <AsyncRoute
          path="/:gameType/results"
          getComponent={() =>
            import("./components/results/results_render").then(
              (module) => module.default
            )
          }
          loading={() => <Loading />}
        />
        {/* @ts-expect-error: AsyncRoute cannot be used as JSX component */}
        <AsyncRoute
          path="/:gameType/:optionsKey"
          getComponent={() =>
            import("./components/game/game_render").then(
              (module) => module.default
            )
          }
          loading={() => <Loading />}
        />
      </Router>
      <ModalContainer />
    </Provider>
  );
}
