import Modal from "react-modal";
import styled from "styled-components/macro";

import { RedBtn } from "../buttons";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 5 },
};

const ErrorTitle = styled.h1`
  color: red;
`;

// Modal.setAppElement('#root')
const ErrorOptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

function ErrorModule({ modalOpen, closeModal, modalData }) {
  const onClose = () => {
    modalData.onModalPrompt(true);
    closeModal();
  };
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Prompt Modal"
      ariaHideApp={false}
    >
      <ErrorTitle>Error</ErrorTitle>
      <h2>{modalData.heading}</h2>
      <ErrorOptionsWrapper>
        <RedBtn onClick={onClose}>Ok</RedBtn>
      </ErrorOptionsWrapper>
    </Modal>
  );
}

export default ErrorModule;
