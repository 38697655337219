import { setActionOpts } from "../actions/setter";
import {
  DEFAULT_INTERVALS_SELECTED,
  NONE_DISPLAY,
  RANGE_C4,
  RANGE_C3,
  SHEET_MUSIC_DISPLAY,
  MULTIPLE_CHOICE,
  NOTE_OPTIONS_REVERSE_MAP,
  NOTE_DISPLAY_OPTIONS_REVERSE_MAP,
  INPUT_DEVICE_OPTIONS_REVERSE_MAP,
  DEFAULT_PLAY_MODE_OPTS,
  DEFAULT_MELODY_KEYS_SELECTED,
} from "../constants/settings_constants";
import { INTERVALS, MELODIES, NOTES } from "./constants";
import { determineStartingOptionsState } from "./utils";

export const DEFAULT_SETTINGS = {
  // Internal settings
  settingsKey: "",
  order: 0,
  // Display options
  optionName: "",
  optionDescription: "",
  // Input options
  inputDeviceOpt: "0", // Multiple Choice
  micDeviceOpt: null,
  numberOfMultipleChoiceQuestionsOpt: 4,
  showNoteDisplayOpt: false,
  multipleChoicesAreOfOpt: "0", // Individual Notes
  // Output options
  playOutputSoundsOpt: true,
  outputSoundsOpt: process.env.DEFAULT_INSTRUMENT,
  playSuccessSoundOpt: false,
  successSoundsOpt: process.env.DEFAULT_SUCCESS_SOUND,
  onlyPlayFirstNoteOpt: false,
  // Game options
  numberOfQuestionsOpt: 15,
  tempoOpt: 80,
  autoContinueCorrectOpt: true,
  autoContinueCorrectDelayOpt: 2,
  repeatPreviewCountOpt: 0,
  // Note Display options
  noteDisplayOpt: "0", // Note Display
  numberOfFretsOpt: 19,
  showNotesOpt: "0", // Only after play
  showGameTypeNameOpt: false,
  // Shared options
  fixedRootOpt: NOTE_OPTIONS_REVERSE_MAP["C3"],
  upperRangesOpt: NOTE_OPTIONS_REVERSE_MAP["C2"],
  lowerRangesOpt: NOTE_OPTIONS_REVERSE_MAP["C6"],
  // Interval-specific options
  intervalsOpt: DEFAULT_INTERVALS_SELECTED,
  playModesOpt: DEFAULT_PLAY_MODE_OPTS,
  // Melody-specific options
  numberOfMelodyNotesOpt: 4,
  melodyKeysOpt: DEFAULT_MELODY_KEYS_SELECTED,
  randomizeRhythmOpt: false,
};
export const nonGameTypeKeys = ["settingsVersion", "selectedOptionsKey"];
const initialState = {
  settingsVersion: "1",
  selectedSettingsKey: "1",
  [NOTES]: {
    // Use 100 for new settings, leaving 100 slots for default settings
    optionKey: 100,
    // All settings group names are a number
    1: {
      settingsKey: 1,
      order: 0,
      optionName: "See Note Name and Play (practice name / locations)",
      optionDescription:
        "Note name will be shown, and must be played into a microphone or with a midi device. Includes notes within an octave around C3.",
      upperRangesOpt: RANGE_C4,
    },
    2: {
      settingsKey: 2,
      order: 1,
      optionName: "See Sheet Music and Play (practice reading music)",
      optionDescription:
        "Note will be shown in sheet music, and must be played into a microphone or with a midi device. Includes notes within an octave around C3.",
      upperRangesOpt: RANGE_C4,
      noteDisplayOpt: NOTE_DISPLAY_OPTIONS_REVERSE_MAP[SHEET_MUSIC_DISPLAY],
    },
    3: {
      settingsKey: 3,
      order: 2,
      optionName: "Hear Note and Play (absolute pitch training)",
      optionDescription:
        "Note will be played, and must be played into a microphone or with a midi device based on the pitch you hear. Includes notes between C3 and C4.",
      lowerRangesOpt: RANGE_C3,
      upperRangesOpt: RANGE_C4,
      noteDisplayOpt: NOTE_DISPLAY_OPTIONS_REVERSE_MAP[NONE_DISPLAY],
    },
    4: {
      settingsKey: 4,
      order: 3,
      optionName: "See Note and Sing (absolute pitch training)",
      optionDescription:
        "Note will be shown and must be sang into a microphone. Includes notes between C3 and C4.",
      lowerRangesOpt: RANGE_C3,
      upperRangesOpt: RANGE_C4,
      playOutputSoundsOpt: false,
    },
    5: {
      settingsKey: 5,
      order: 4,
      optionName:
        "See Sheet Music and Choose (practice reading music without playing)",
      optionDescription:
        "Note will be shown in sheet music and selected from multiple choice options. Includes notes between C3 and C4.",
      lowerRangesOpt: RANGE_C3,
      upperRangesOpt: RANGE_C4,
      playOutputSoundsOpt: true,
      inputDeviceOpt: INPUT_DEVICE_OPTIONS_REVERSE_MAP[MULTIPLE_CHOICE],
      noteDisplayOpt: NOTE_DISPLAY_OPTIONS_REVERSE_MAP[SHEET_MUSIC_DISPLAY],
      showNoteDisplayOpt: false,
    },
  },
  [INTERVALS]: {
    optionKey: 100,
    1: {
      settingsKey: 1,
      order: 0,
      optionName: "Hear Interval and Play",
      optionDescription:
        "First note will be shown, the second must be played into a microphone or with a midi device based on the pitch you hear. Includes all ascending and descending intervals within an octave around a fixed C3 note root.",
    },
    2: {
      settingsKey: 2,
      order: 1,
      optionName: "See Interval and Sing",
      optionDescription:
        "Interval name will be shown and first note will be played, the pitch of the second note must be sung from memory. Includes all ascending and descending intervals within an octave around a fixed C3 note root.",
      onlyPlayFirstNoteOpt: true,
      noteDisplayOpt: NOTE_DISPLAY_OPTIONS_REVERSE_MAP[NONE_DISPLAY],
      showGameTypeNameOpt: true,
    },
    3: {
      settingsKey: 3,
      order: 2,
      optionName: "Hear Interval and Choose",
      optionDescription:
        "The Interval will be played and you must choose which interval you heard. Includes all ascending and descending intervals within an octave around a fixed C3 note root.",
      noteDisplayOpt: NOTE_DISPLAY_OPTIONS_REVERSE_MAP[NONE_DISPLAY],
      inputDeviceOpt: INPUT_DEVICE_OPTIONS_REVERSE_MAP[MULTIPLE_CHOICE],
    },
    4: {
      settingsKey: 4,
      order: 3,
      optionName: "Sight Read Interval",
      optionDescription:
        "Interval name will be shown in sheet music and the first note will be played, the pitch of the second note must be sung from memory. Includes all ascending and descending intervals within an octave around a fixed C3 note root.",
      onlyPlayFirstNoteOpt: true,
      noteDisplayOpt: NOTE_DISPLAY_OPTIONS_REVERSE_MAP[SHEET_MUSIC_DISPLAY],
    },
  },
  [MELODIES]: {
    optionKey: 100,
    1: {
      settingsKey: 1,
      optionName: "Default melody!",
      optionDescription: "Default game mode",
      order: 0,
    },
  },
};

export const GAME_OPTIONS_REDUCER = "gameOptions";

const startingState = determineStartingOptionsState(
  GAME_OPTIONS_REDUCER,
  initialState
);

export default (
  state = startingState,
  action: {
    type: string;
    payload: any;
    reducer: string;
    opts: setActionOpts;
  }
) => {
  const { type, payload, reducer, opts } = action;

  if (reducer === GAME_OPTIONS_REDUCER) {
    if (!type && !payload) {
      return state;
    }

    let newState = state;
    // Set specific option in a group in a type
    if (opts.setKey && opts.setGroup && opts.setType) {
      newState = {
        ...state,
        [opts.setType]: {
          ...state[opts.setType],
          [opts.setGroup]: {
            ...state[opts.setType][opts.setGroup],
            [opts.setKey]: payload,
          },
        },
      };
      // Set entire type (e.g. reordering intervals)
    } else if (opts.setType && !opts.setGroup) {
      newState = {
        ...state,
        [opts.setType]: {
          ...state[opts.setType],
          ...payload,
        },
      };
      // Set specific options in type (e.g. a specific interval)
    } else if (opts.setGroup) {
      let optionKey = state[opts.setType].optionKey;
      if (optionKey <= opts.setGroup) {
        optionKey = opts.setGroup + 1;
      }
      newState = {
        ...state,
        [opts.setType]: {
          ...state[opts.setType],
          optionKey,
          [opts.setGroup]: payload,
        },
      };
    } else {
      newState = {
        ...state,
        [type]: payload,
      };
    }
    if (typeof window !== "undefined") {
      localStorage.setItem(GAME_OPTIONS_REDUCER, JSON.stringify(newState));
    }
    return newState;
  }

  switch (type) {
    default:
      return state;
  }
};
