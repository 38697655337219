// Game states
export const PLAYING_STATE = "Playing...";
export const LISTENING_STATE = "Listening...";
export const ANSWERED_STATE = "Answered";
export const WRONG_STATE = "Wrong";

const initialState = {
  isTheEndOverride: false,
  isQuestionBeforeLast: false,
  numberCorrect: 0,
  gameState: PLAYING_STATE,
  repeating: null,
  noteDisplayIsLoading: true,
  notePlayed: null,
  answerResult: null,
  correctIndex: 0, // all items before this index in this.songNotes have been played
  correctHarmonics: [], // The harmonics in a chord that have been played correctly
};

export const GAME_STATE_REDUCER = "gameState";

export default (state = initialState, action) => {
  const { type, payload, reducer, opts } = action;

  if (reducer === GAME_STATE_REDUCER) {
    if (opts.reset) {
      return initialState;
    }
    if (opts.increment) {
      return {
        ...state,
        [type]: state[type] + 1,
      };
    }
    return {
      ...state,
      [type]: payload,
    };
  }

  switch (type) {
    default:
      return state;
  }
};
