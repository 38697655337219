// - - - - - -
// Input Settings
// - - - - - -
// Input engine
export const MULTIPLE_CHOICE = "Multiple Choice (No device needed)";
export const MIDI_DEVICE = "MIDI Device (High Accuracy)";
export const MICROPHONE_DEVICE = "Microphone (Frequency detection)";

export const INPUT_DEVICE_OPTIONS_MAP = {
  0: MULTIPLE_CHOICE,
  1: MIDI_DEVICE,
  2: MICROPHONE_DEVICE,
};
export const INPUT_DEVICE_OPTIONS_REVERSE_MAP = {
  [MULTIPLE_CHOICE]: "0",
  [MIDI_DEVICE]: "1",
  [MICROPHONE_DEVICE]: "2",
};

export const CHOICES_ARE_INDIVIDUAL_NOTES = "Individual Notes";
export const CHOICES_ARE_INTERVALS = "Interval Names";
export const CHOICES_ARE_CHORDS = "Chords";
export const MULTIPLE_CHOICES_ARE_OF_INTERVALS_MAP = {
  0: CHOICES_ARE_INTERVALS,
  1: CHOICES_ARE_INDIVIDUAL_NOTES,
};
export const MULTIPLE_CHOICES_ARE_OF_INTERVALS_REVERSE_MAP = {
  0: CHOICES_ARE_INTERVALS,
  1: CHOICES_ARE_INDIVIDUAL_NOTES,
};

export const MULTIPLE_CHOICES_ARE_OF_CHORDS_MAP = {
  0: CHOICES_ARE_CHORDS,
  1: CHOICES_ARE_INDIVIDUAL_NOTES,
};

// - - - - - -
// Global settings
// - - - - - -
// Starting Note Display
export const SHEET_MUSIC_DISPLAY = "Sheet Music";
export const PIANO_DISPLAY = "Piano Keyboard";
export const NOTE_DISPLAY = "Note Names";
export const INTERVAL_DISPLAY = "Interval Name";
export const GUITAR_FRETBOARD_DISPLAY = "Guitar Fretboard";
export const NONE_DISPLAY = "None";

export const NOTE_DISPLAY_OPTIONS_MAP = {
  0: NOTE_DISPLAY,
  1: SHEET_MUSIC_DISPLAY,
  2: GUITAR_FRETBOARD_DISPLAY,
  3: PIANO_DISPLAY,
  4: INTERVAL_DISPLAY,
  5: NONE_DISPLAY,
};

export const NOTE_DISPLAY_OPTIONS_REVERSE_MAP = {
  [NOTE_DISPLAY]: "0",
  [SHEET_MUSIC_DISPLAY]: "1",
  [GUITAR_FRETBOARD_DISPLAY]: "2",
  [PIANO_DISPLAY]: "3",
  [INTERVAL_DISPLAY]: "4",
  [NONE_DISPLAY]: "5",
};

// Show notes option
export const SEE_ONLY_AFTER_PLAY = "Only see notes after they are played";
export const SEE_ALL = "See all notes";
export const SEE_FIRST = "Only see starting note";

export const SHOW_NOTES_OPTIONS_MAP = {
  0: SEE_ONLY_AFTER_PLAY,
  1: SEE_ALL,
  2: SEE_FIRST,
};
export const SHOW_NOTES_OPTIONS_REVERSE_MAP = {
  [SEE_ONLY_AFTER_PLAY]: "0",
  [SEE_ALL]: "1",
  [SEE_FIRST]: "2",
};

// - - - - - -
// Tab settings
// - - - - - -
// Intervals
export const MINOR_2ND = "Minor 2nd";
export const MAJOR_2ND = "Major 2nd";
export const MINOR_3RD = "Minor 3rd";
export const MAJOR_3RD = "Major 3nd";
export const PERFECT_4TH = "Perfect 4th";
export const DIM_5TH = "Dim 5th";
export const PERFECT_5TH = "Perfect 5th";
export const MINOR_6TH = "Minor 6th";
export const MAJOR_6TH = "Major 6th";
export const MINOR_7TH = "Minor 7th";
export const MAJOR_7TH = "Major 7th";
export const OCTAVE = "Octave";

export const INTERVAL_OPTIONS_MAP = {
  0: MINOR_2ND,
  1: MAJOR_2ND,
  2: MINOR_3RD,
  3: MAJOR_3RD,
  4: PERFECT_4TH,
  5: DIM_5TH,
  6: PERFECT_5TH,
  7: MINOR_6TH,
  8: MAJOR_6TH,
  9: MINOR_7TH,
  10: MAJOR_7TH,
  11: OCTAVE,
};
export const INTERVAL_OPTIONS_REVERSE_MAP = {
  [MINOR_2ND]: "0",
  [MAJOR_2ND]: "1",
  [MINOR_3RD]: "2",
  [MAJOR_3RD]: "3",
  [PERFECT_4TH]: "4",
  [DIM_5TH]: "5",
  [PERFECT_5TH]: "6",
  [MINOR_6TH]: "7",
  [MAJOR_6TH]: "8",
  [MINOR_7TH]: "9",
  [MAJOR_7TH]: "10",
  [OCTAVE]: "11",
};

export const DEFAULT_INTERVALS_SELECTED = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
];

export const INTERVAL_TO_SEMITONE_MAP = {
  [MINOR_2ND]: 1,
  [MAJOR_2ND]: 2,
  [MINOR_3RD]: 3,
  [MAJOR_3RD]: 4,
  [PERFECT_4TH]: 5,
  [DIM_5TH]: 6,
  [PERFECT_5TH]: 7,
  [MINOR_6TH]: 8,
  [MAJOR_6TH]: 9,
  [MINOR_7TH]: 10,
  [MAJOR_7TH]: 11,
  [OCTAVE]: 12,
};
export const SEMITONE_TO_INTERVAL_MAP = {
  1: MINOR_2ND,
  2: MAJOR_2ND,
  3: MINOR_3RD,
  4: MAJOR_3RD,
  5: PERFECT_4TH,
  6: DIM_5TH,
  7: PERFECT_5TH,
  8: MINOR_6TH,
  9: MAJOR_6TH,
  10: MINOR_7TH,
  11: MAJOR_7TH,
  12: OCTAVE,
};

// Play Modes
export const ASCENDING = "Ascending";
export const DESCENDING = "Descending";
export const HARMONIC = "Harmonic";

export const PLAY_MODES_OPTIONS_MAP = {
  0: ASCENDING,
  1: DESCENDING,
  2: HARMONIC,
};
export const PLAY_MODES_OPTIONS_REVERSE_MAP = {
  [ASCENDING]: "0",
  [DESCENDING]: "1",
  [HARMONIC]: "2",
};

export const DEFAULT_PLAY_MODE_OPTS = ["0", "1", "2"];

// All Notes
export const NOTE_OPTIONS_MAP = {
  "0": "C2",
  "1": "C#2",
  "2": "D2",
  "3": "D#2",
  "4": "E2",
  "5": "F2",
  "6": "F#2",
  "7": "G2",
  "8": "G#2",
  "9": "A2",
  "10": "A#2",
  "11": "B2",
  "12": "C3",
  "13": "C#3",
  "14": "D3",
  "15": "D#3",
  "16": "E3",
  "17": "F3",
  "18": "F#3",
  "19": "G3",
  "20": "G#3",
  "21": "A3",
  "22": "A#3",
  "23": "B3",
  "24": "C4",
  "25": "C#4",
  "26": "D4",
  "27": "D#4",
  "28": "E4",
  "29": "F4",
  "30": "F#4",
  "31": "G4",
  "32": "G#4",
  "33": "A4",
  "34": "A#4",
  "35": "B4",
  "36": "C5",
  "37": "C#5",
  "38": "D5",
  "39": "D#5",
  "40": "E5",
  "41": "F5",
  "42": "F#5",
  "43": "G5",
  "44": "G#5",
  "45": "A5",
  "46": "A#5",
  "47": "B5",
  "48": "C6",
};
export const NOTE_OPTIONS_REVERSE_MAP = {
  C2: "0",
  "C#2": "1",
  D2: "2",
  "D#2": "3",
  E2: "4",
  F2: "5",
  "F#2": "6",
  G2: "7",
  "G#2": "8",
  A2: "9",
  "A#2": "10",
  B2: "11",
  C3: "12",
  "C#3": "13",
  D3: "14",
  "D#3": "15",
  E3: "16",
  F3: "17",
  "F#3": "18",
  G3: "19",
  "G#3": "20",
  A3: "21",
  "A#3": "22",
  B3: "23",
  C4: "24",
  "C#4": "25",
  D4: "26",
  "D#4": "27",
  E4: "28",
  F4: "29",
  "F#4": "30",
  G4: "31",
  "G#4": "32",
  A4: "33",
  "A#4": "34",
  B4: "35",
  C5: "36",
  "C#5": "37",
  D5: "38",
  "D#5": "39",
  E5: "40",
  F5: "41",
  "F#5": "42",
  G5: "43",
  "G#5": "44",
  A5: "45",
  "A#5": "46",
  B5: "47",
  C6: "48",
};

export const NOT_FIXED_ROOT = "Not Fixed";
export const RANGE_C3 = NOTE_OPTIONS_REVERSE_MAP["C3"];
export const RANGE_C4 = NOTE_OPTIONS_REVERSE_MAP["C3"];

// Keys
// Intervals
export const MAJOR = "Major";
export const MAJOR_PENTATONIC = "Major Pentatonic";
export const MINOR = "Minor";
export const MINOR_PENTATONIC = "Minor Pentatonic";
export const MELODIC_MINOR = "Melodic Minor";
export const NATURAL_MINOR = "Natural Minor";
export const DORIAN = "Dorian";
export const PHRYGIAN = "Phrygian";
export const LYDIAN = "Lydian";
export const MIXOLYDIAN = "Mixolydian";
export const LOCRIAN = "Locrian";

export const MELODY_KEYS_OPTIONS_MAP = {
  0: MAJOR,
  1: MAJOR_PENTATONIC,
  2: MINOR,
  3: MINOR_PENTATONIC,
  4: MELODIC_MINOR,
  5: NATURAL_MINOR,
  6: DORIAN,
  7: PHRYGIAN,
  8: LYDIAN,
  9: MIXOLYDIAN,
  10: LOCRIAN,
};
export const MELODY_KEYS_OPTIONS_REVERSE_MAP = {
  [MAJOR]: "0",
  [MAJOR_PENTATONIC]: "1",
  [MINOR]: "2",
  [MINOR_PENTATONIC]: "3",
  [MELODIC_MINOR]: "4",
  [NATURAL_MINOR]: "5",
  [DORIAN]: "6",
  [PHRYGIAN]: "7",
  [LYDIAN]: "8",
  [MIXOLYDIAN]: "9",
  [LOCRIAN]: "10",
};

export const DEFAULT_MELODY_KEYS_SELECTED = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const KEY_TO_SEMITONES = {
  [MAJOR]: [0, 2, 4, 5, 7, 9, 11, 12],
  [MAJOR_PENTATONIC]: [0, 2, 4, 7, 9, 12, 14, 16, 19, 21, 24],
  [MINOR]: [0, 2, 3, 5, 7, 8, 10, 12],
  [MINOR_PENTATONIC]: [0, 3, 5, 7, 10, 12, 15, 17, 19, 22, 24],
  [MELODIC_MINOR]: [0, 2, 3, 5, 7, 9, 11, 12],
  [NATURAL_MINOR]: [0, 2, 3, 5, 7, 8, 11, 12],
  [DORIAN]: [0, 2, 3, 5, 7, 9, 10, 12],
  [PHRYGIAN]: [0, 1, 3, 5, 7, 8, 10, 12],
  [LYDIAN]: [0, 2, 4, 6, 7, 9, 11, 12],
  [MIXOLYDIAN]: [0, 2, 4, 5, 7, 9, 10, 12],
  [LOCRIAN]: [0, 1, 3, 5, 6, 8, 10, 12],
};

// Note durations
export const NOTE_DURATIONS = ["2n", "4n", "8n"];

// Nuber of questions
export const INFINITE = "Infinite";

// - - - - - -
// Instrument and Sound settings
// - - - - - -
export const OUTPUT_SOUNDS_OPTIONS_MAP = {
  acoustic_guitar: "Acoustic Guitar",
  emotional_piano: "Emotional Piano",
  great_and_soft_piano: "Great And Soft Piano",
  hard_and_tough_piano: "Hard And Tough Piano",
  hard_piano: "Hard Piano",
  harp: "Harp",
  harpsicord: "Harpsicord",
  loud_and_proud_piano: "Loud And Proud Piano",
  music_box: "Music Box",
  new_piano: "New Piano",
  orchestra_piano: "Orchestra Piano",
  rhodes_piano: "Rhodes Piano",
  salamander_grand_piano: "Salamander Grand Piano",
  soft_piano: "Soft Piano",
  spanish_classic_guitar: "Spanish Classic Guitar",
  steinway_grand_piano: "Steinway Grand Piano",
  vintage_upright_piano: "Vintage Upright Piano",
  vintage_upright_soft_piano: "Vintage Upright Soft Piano",
};
export const OUTPUT_SOUNDS_OPTIONS_REVERSE_MAP = {
  "Acoustic Guitar": "acoustic_guitar",
  "Emotional Piano": "emotional_piano",
  "Great And Soft Piano": "great_and_soft_piano",
  "Hard And Tough Piano": "hard_and_tough_piano",
  "Hard Piano": "hard_piano",
  Harp: "harp",
  Harpsicord: "harpsicord",
  "Loud And Proud Piano": "loud_and_proud_piano",
  "Music Box": "music_box",
  "New Piano": "new_piano",
  "Orchestra Piano": "orchestra_piano",
  "Rhodes Piano": "rhodes_piano",
  "Salamander Grand Piano": "salamander_grand_piano",
  "Soft Piano": "soft_piano",
  "Spanish Classic Guitar": "spanish_classic_guitar",
  "Steinway Grand Piano": "steinway_grand_piano",
  "Vintage Upright Piano": "vintage_upright_piano",
  "Vintage Upright Soft Piano": "vintage_upright_soft_piano",
};

// Sounds
export const SUCCESS_SOUNDS_OPTIONS_MAP = {
  "success_1.mp3": "Success 1",
  "success_2.mp3": "Success 2",
  "success_3.mp3": "Success 3",
  "fail_1.mp3": "Fail 1",
  "fail_2.mp3": "Fail 2",
  "fail_3.mp3": "Fail 3",
};
export const SUCCESS_SOUNDS_OPTIONS_REVERSE_MAP = {
  "Success 1": "success_1.mp3",
  "Success 2": "success_2.mp3",
  "Success 3": "success_3.mp3",
  "Fail 1": "fail_1.mp3",
  "Fail 2": "fail_2.mp3",
  "Fail 3": "fail_3.mp3",
};
