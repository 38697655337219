import { combineReducers } from "redux";

import gameOptions, { GAME_OPTIONS_REDUCER } from "./game_options";
import gameState, { GAME_STATE_REDUCER } from "./game_state";
import globalState, { GLOBAL_STATE_REDUCER } from "./global_state";
import modalState, { MODAL_STATE_REDUCER } from "./modal_state";
import userGestureState, { USER_GESTURE_REDUCER } from "./user_gesture";

export default combineReducers({
  [GAME_OPTIONS_REDUCER]: gameOptions,
  [GAME_STATE_REDUCER]: gameState,
  [GLOBAL_STATE_REDUCER]: globalState,
  [MODAL_STATE_REDUCER]: modalState,
  [USER_GESTURE_REDUCER]: userGestureState,
});
