import { useSelector } from "react-redux";

import setAction from "../../actions/setter";
import { MODAL_STATE_REDUCER } from "../../reducers/modal_state";
import ErrorModule from "./error_modal";
import InputOptionsModal from "./input_options_modal";
import PromptModal from "./prompt_modal";

export default function ModalContainer() {
  // Modal State
  const promptModalData = useSelector(
    (state) => state[MODAL_STATE_REDUCER].promptModalData
  );
  const errorModalData = useSelector(
    (state) => state[MODAL_STATE_REDUCER].errorModalData
  );
  const inputOptionsModalData = useSelector(
    (state) => state[MODAL_STATE_REDUCER].inputOptionsModalData
  );

  return (
    <>
      <PromptModal
        modalOpen={!!promptModalData.onModalPrompt}
        closeModal={() => setAction(MODAL_STATE_REDUCER, "promptModalData", {})}
        modalData={promptModalData}
      />
      <ErrorModule
        modalOpen={!!errorModalData.onModalPrompt}
        closeModal={() => setAction(MODAL_STATE_REDUCER, "errorModalData", {})}
        modalData={errorModalData}
      />
      <InputOptionsModal
        modalOpen={!!inputOptionsModalData.open}
        closeModal={() =>
          setAction(MODAL_STATE_REDUCER, "inputOptionsModalData", {})
        }
        modalData={inputOptionsModalData}
      />
    </>
  );
}
