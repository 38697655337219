import { useCallback } from "react";
import Modal from "react-modal";
import styled from "styled-components/macro";

import { onEnterKey } from "../../utils/on_key_events";
import { GreenBtn, RedBtn } from "../buttons";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 3 },
};

// Modal.setAppElement('#root')
const PromptOptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PromptHeading = styled.h2`
  font-size: 1.5rem;
  text-align: center;
`;

function PromptModal({ modalOpen, closeModal, modalData }) {
  const onConfirm = useCallback(
    () => modalData.onModalPrompt(true),
    [modalData]
  );
  const onDecline = useCallback(() => {
    modalData.onModalPrompt(false);
    closeModal();
  }, [modalData]);

  onEnterKey(onConfirm, modalOpen);

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={onDecline}
      style={customStyles}
      contentLabel="Prompt Modal"
      ariaHideApp={false}
    >
      <PromptHeading>{modalData.heading}</PromptHeading>
      <PromptOptionsWrapper>
        <GreenBtn onClick={onConfirm}>{modalData.yes ?? "Yes"}</GreenBtn>
        <RedBtn onClick={onDecline}>{modalData.no ?? "No"}</RedBtn>
      </PromptOptionsWrapper>
    </Modal>
  );
}

export default PromptModal;
