import { useState, useEffect, useMemo, useCallback } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import setAction from "../../actions/setter";
import {
  THEME_DARK_COLOR,
  THEME_SPLASH_COLOR,
} from "../../constants/style_constants";
import {
  DEFAULT_SETTINGS,
  GAME_OPTIONS_REDUCER,
} from "../../reducers/game_options";
import InputOptions from "../option_tabs/input_options";
import CloseIcon from "../svg/close_icon";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 2 },
};

const ModalContentWrapper = styled.div`
  max-width: 75vw;
  min-width: 75vw;
  padding: 15px;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  padding: 0;
  background-color: white;
  svg {
    fill: ${THEME_DARK_COLOR};
    width: 50px;
  }
  border-bottom: 1px solid transparent;
  :hover {
    cursor: pointer;
    border-bottom: 1px solid ${THEME_SPLASH_COLOR};
    svg {
      fill: ${THEME_SPLASH_COLOR};
    }
  }
`;

const ModalText = styled.p`
  font-weight: bold;
  font-size: 1rem;
`;

function InputOptionsModal({ modalOpen, closeModal, modalData }) {
  let onModalClose = () => {
    closeModal();
  };

  let ModalContent = null;
  if (modalOpen) {
    const { onClose, hideMicTest, gameType, optionsKey, message } = modalData;
    const selectedOptions = useSelector(
      (store) => store[GAME_OPTIONS_REDUCER][gameType][optionsKey]
    );
    const [options, setOptions] = useState(selectedOptions || {});
    useEffect(() => {
      setOptions(selectedOptions);
    }, [selectedOptions]);
    onModalClose = useCallback(() => {
      // Update reducer options
      // When browser idles the options get reset and this overwrites the settings, so only fire if settings are valid
      if (selectedOptions?.settingsKey && options?.settingsKey) {
        setAction(GAME_OPTIONS_REDUCER, "", options, {
          setGroup: optionsKey,
          setType: gameType,
        });
      }
      onClose();
      closeModal();
    }, [onClose, closeModal, options, optionsKey, gameType, selectedOptions]);
    const {
      // Input options
      inputDeviceOpt,
      multipleChoicesAreOfOpt,
      numberOfMultipleChoiceQuestionsOpt,
    } = useMemo(() => {
      return {
        ...DEFAULT_SETTINGS,
        ...options,
      };
    }, [options]);
    const setGameOption = useCallback(
      (variableName: string) => {
        return (variableValue: any) => {
          setOptions({
            ...options,
            [variableName]: variableValue,
          });
        };
      },
      [options, setOptions]
    );

    ModalContent = (
      <ModalContentWrapper>
        <ModalCloseButton onClick={onModalClose}>
          <CloseIcon />
        </ModalCloseButton>
        <ModalText>{message}</ModalText>
        <InputOptions
          gameType={gameType}
          inputDeviceOpt={inputDeviceOpt}
          hideMicTest={hideMicTest}
          setGameOption={setGameOption}
          multipleChoicesAreOfOpt={multipleChoicesAreOfOpt}
          numberOfMultipleChoiceQuestionsOpt={
            numberOfMultipleChoiceQuestionsOpt
          }
        />
      </ModalContentWrapper>
    );
  }
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={onModalClose}
      style={customStyles}
      contentLabel="Input Options Modal"
      ariaHideApp={false}
    >
      {ModalContent}
    </Modal>
  );
}

export default InputOptionsModal;
