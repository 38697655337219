/** @ts-ignore */
// eslint-disable-next-line import/default
import Worker from "./audio.worker.js";

export async function getStreamAndName(): Promise<{
  name: string;
  stream: MediaStream;
}> {
  if (typeof window === "undefined") {
    return undefined;
  }
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    let name = "";
    const tracks = stream.getAudioTracks();
    if (tracks.length) {
      name = tracks[0].label;
    }
    return { name, stream };
  } catch (error) {
    console.error("Unable to get source stream and name");
    console.error(error);
  }
  return undefined;
}

const nullFunc = () => {};

export default class Microphone {
  listening: boolean;
  onNotePlayed: (data: any) => void;
  setIsListening: (data: any) => void;
  setErrorModalData: (data: any) => void;
  audioContext: any;
  refreshHandle: any;
  audioProcessor: any;
  mediaRecorder: any;
  sourceStream: any;
  deviceName: string;
  _init: boolean;
  constructor(
    onNotePlayed: (note: string) => void,
    setErrorModalData: (data: any) => void,
    setIsListening?: (data: any) => void
  ) {
    if (typeof window === "undefined") {
      return;
    }
    this.audioContext = new AudioContext();
    // Callback that is called when a note is played after listening
    this.onNotePlayed = onNotePlayed;
    // Callbackt that is fired when listening has begun
    this.setIsListening = setIsListening || nullFunc;
    this.setErrorModalData = setErrorModalData;
    this.listening = null;
    this.refreshHandle = null;
    this.audioProcessor = null;
    this.mediaRecorder = null;
    this.deviceName = null;
    this._init = false;
  }

  async init() {
    if (this._init) {
      return true;
    }
    try {
      const { name, stream } = await getStreamAndName();
      this.sourceStream = stream;
      this.deviceName = name;
    } catch (error) {
      try {
        const { name, stream } = await getStreamAndName();
        this.sourceStream = stream;
        this.deviceName = name;
      } catch (error) {
        console.error(error);
        this.setErrorModalData({
          heading: "Please enable microphone access and try again.",
          onModalPrompt: () => {
            this.setErrorModalData({});
          },
        });
        return;
      }
    }
    // Setup listener to detect if sourceStream has changed and to refresh it
    navigator.mediaDevices.ondevicechange = async () => {
      const listening = this.getListening();
      if (listening) {
        this.stop();
      }
      const { name, stream } = await getStreamAndName();
      this.sourceStream = stream;
      this.deviceName = name;
      if (listening) {
        this.listen();
      }
    };

    this._init = true;
    return true;
  }

  getListening() {
    return this.listening;
  }

  destroy() {
    this.stop();
    if (this.audioProcessor) {
      this.audioProcessor.onmessage = null;
      this.audioProcessor.terminate();
      this.audioProcessor = null;
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = null;
      this.mediaRecorder = null;
    }
    this.sourceStream = null;
    this.audioContext = null;
    this._init = false;
  }

  stop() {
    this.listening = false;
    clearInterval(this.refreshHandle);
    try {
      if (this.audioProcessor) {
        this.audioProcessor.terminate();
      }
    } catch (err) {
      console.log("audioProcessor error on stop");
      console.error(err);
    }
    try {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }
    } catch (err) {
      console.log("MediaRecorder inactive on stop");
    }
    this.setIsListening(false);
  }

  listen() {
    console.log("Listening on mic input device...");
    if (!this._init) {
      console.error(
        "Microphone class has not been initialized. Must call await init() first"
      );
      return;
    }
    this.listening = true;
    this.audioProcessor = new Worker();
    this.audioProcessor.onmessage = this.handleProcessorMessage.bind(this);
    this.mediaRecorder = new MediaRecorder(this.sourceStream);

    this.mediaRecorder.ondataavailable = this.update.bind(this);
    this.mediaRecorder.start();
    this.setIsListening(true);
    setTimeout(() => this.listening && this.mediaRecorder.stop(), 400);

    // Every 200ms, send whatever has been recorded to the audio processor.
    this.refreshHandle = setInterval(() => {
      this.listening && this.mediaRecorder.start();
      setTimeout(() => this.listening && this.mediaRecorder.stop(), 400);
    }, 500);
  }

  // Handles responses received from the audio processing web worker.
  handleProcessorMessage(e) {
    if (this.listening) {
      if (e.data) {
        // Send notes to callback
        this.onNotePlayed(e.data);
      }
    }
  }

  // Handles data received from a `MediaRecorder`.
  async update(e) {
    if (e.data.size !== 0) {
      // Load the blob.
      const response = await fetch(URL.createObjectURL(e.data));
      const arrayBuffer = await response.arrayBuffer();
      // Decode the audio.
      const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
      const audioData = audioBuffer.getChannelData(0);
      if (!this.audioProcessor) {
        return;
      }
      // Send the audio data to the audio processing worker.
      this.audioProcessor.postMessage({
        sampleRate: audioBuffer.sampleRate,
        audioData,
      });
    }
  }
}
