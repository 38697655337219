import { INTERVALS } from "./constants";
import { determineStartingState } from "./utils";

const initialState = {
  gameOptionsTab: INTERVALS,
  results: {},
};

export const GLOBAL_STATE_REDUCER = "globalState";

const resolvedState = determineStartingState(
  GLOBAL_STATE_REDUCER,
  initialState
);

export default (state = resolvedState, action) => {
  const { type, payload, reducer } = action;

  if (reducer === GLOBAL_STATE_REDUCER) {
    const newState = {
      ...state,
      [type]: payload,
    };
    if (typeof window !== "undefined") {
      localStorage.setItem(GLOBAL_STATE_REDUCER, JSON.stringify(newState));
    }
    return newState;
  }

  switch (type) {
    default:
      return state;
  }
};
