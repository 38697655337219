import { memo } from "react";
import styled from "styled-components/macro";

import {
  THEME_SPLASH_COLOR,
  THEME_BORDER_COLOR,
} from "../../constants/style_constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: start;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  position: relative;
`;

type LabelProps = {
  checked: boolean;
};
const Label = styled.div<LabelProps>`
  font-size: 1.1rem;
  padding-top: 0.1rem;
  ${(props) =>
    props.checked &&
    `
    color: ${THEME_SPLASH_COLOR};
    font-weight: 600;
  `}
`;
const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid ${THEME_BORDER_COLOR};
  margin-left: 0.5rem;
`;

type RadioButtonProps = {
  checked: boolean;
};
const RadioButton = styled.input<RadioButtonProps>`
  margin-left: 0.9rem;
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: 0.5s;
  margin-right: 10px;
  ${(props) =>
    props.checked
      ? `&:checked + ${RadioButtonLabel} {
      background: ${THEME_SPLASH_COLOR};
      border: 1px solid ${THEME_SPLASH_COLOR};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 6px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
    `
      : `
    &:hover ~ ${RadioButtonLabel} {
      background: ${THEME_BORDER_COLOR};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 6px;
        background: #eeeeee;
      }
    }
  `}
`;

type RadioFormProps = {
  title: string;
  optionsMap: { [key: string]: string };
  excludeOptions?: string[];
  selectedOption: string;
  setValue: (option: string) => void;
};

function RadioForm({
  title,
  optionsMap,
  selectedOption,
  excludeOptions,
  setValue,
}: RadioFormProps) {
  const Options = Object.entries(optionsMap).map((entry) => {
    if (excludeOptions?.includes(entry[0])) {
      return null;
    }
    return (
      <Option key={entry[0]}>
        <RadioButton
          onChange={() => setValue(entry[0])}
          checked={entry[0] === selectedOption}
          type="radio"
        />
        <RadioButtonLabel />
        <Label checked={entry[0] === selectedOption}>{entry[1]}</Label>
      </Option>
    );
  });

  return (
    <Wrapper>
      <Title>{title}</Title>
      <OptionsWrapper>{Options}</OptionsWrapper>
    </Wrapper>
  );
}

export default memo(RadioForm);
