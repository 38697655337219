import { Button as styledBtn } from "styled-button-component";
import styled from "styled-components/macro";

import {
  THEME_SUCCESS_COLOR,
  THEME_BORDER_COLOR,
  THEME_SPLASH_COLOR,
} from "../constants/style_constants";

const Button = styled(styledBtn)`
  z-index: 0;
  white-space: break-spaces;
  padding: ${(props) => (props.small ? ".6rem" : "1rem")};
  font-size: ${(props) => (props.small ? "1rem" : "1.3rem")};
  border: 1px solid ${THEME_BORDER_COLOR};
  border-radius: 25px;
  min-width: ${(props) => (props.small ? "5rem" : "7rem")};
  margin: 0.1rem 0;

  :hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  ${(props) =>
    props.disabled &&
    `
    background-color: grey !important;
  `}
`;

export const GreenBtn = styled(Button)`
  background-color: ${THEME_SUCCESS_COLOR};
  color: white;
  :hover {
    background-color: ${THEME_SPLASH_COLOR} !important;
  }
`;

export const ThemeBtn = styled(Button)`
  background-color: ${THEME_SPLASH_COLOR};
  color: white;
`;

export const RedBtn = styled(Button)`
  background-color: red;
  color: white;
`;
