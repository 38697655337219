import { GAME_OPTIONS_REDUCER } from "./game_options";

export function determineStartingState(key: string, initialState: object): any {
  if (typeof window === "undefined") {
    return initialState;
  }
  let storedState: any;
  try {
    storedState = localStorage.getItem(key);
    storedState = JSON.parse(storedState);
  } catch (error) {
    console.error(`Error loading local state for ${key}`);
  }
  if (storedState) {
    return storedState;
  }
  return initialState;
}

// Migrations for settings state are updated here
export function determineStartingOptionsState(
  key: string,
  initialState: any
): any {
  const storedState = determineStartingState(key, initialState);

  if (storedState.version === initialState.version) {
    return storedState;
  }

  // Run migrations to get stored state up to date
  if (storedState.version < 1) {
    // Migrate from v0 to v1
  } else if (storedState.version < 2) {
    // Migrate from v1 to v2
  } else if (storedState.version < 3) {
    // Migrate from v2 to v3
  } else if (storedState.version < 4) {
    // Migrate from v3 to v4
  } else if (storedState.version < 5) {
    // Migrate from v4 to v5
  } else if (storedState.version < 6) {
    // Etc, etc
  }

  // Update stored settings
  if (typeof window !== "undefined") {
    localStorage.setItem(GAME_OPTIONS_REDUCER, JSON.stringify(storedState));
  }

  return storedState;
}
