import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers/_root_reducer";

let isDev = false;
try {
  isDev = process.env.IS_DEV === "true";
} catch (error) {
  // process.env.IS_DEV is not defined
}

const middleware = [thunk];
let enhancers: any;
if (isDev) {
  const composeEnhancers =
    (typeof window !== "undefined" &&
      // @ts-ignore-next-line
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  enhancers = composeEnhancers(applyMiddleware(...middleware));
} else {
  enhancers = applyMiddleware(...middleware);
}

const store = createStore(rootReducer, enhancers);

if (isDev) {
  // @ts-ignore
  window.store = store;
}

export default store;
