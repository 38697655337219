const initialState = {
  hasUserGesture: false,
};

export const USER_GESTURE_REDUCER = "user_gesture";

export default (state = initialState, action) => {
  const { type, payload, reducer } = action;

  if (reducer === USER_GESTURE_REDUCER) {
    const newState = {
      ...state,
      [type]: payload,
    };
    return newState;
  }

  switch (type) {
    default:
      return state;
  }
};
