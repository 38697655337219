import { memo } from "react";
import styled from "styled-components/macro";

import { INFINITE } from "../../constants/settings_constants";
import {
  THEME_BORDER_COLOR,
  THEME_SPLASH_COLOR,
} from "../../constants/style_constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: start;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Units = styled.div`
  display: flex;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 1.1rem;
  min-width: 4rem;
  max-width: 4rem;
  font-size: 18px;
  padding: 5px;
  background: white;
  border: 1.5px solid ${THEME_BORDER_COLOR};
  border-radius: 3px;
  ::placeholder {
    color: ${THEME_SPLASH_COLOR};
  }
  :focus {
    outline: none !important;
    border: 1.5px solid ${THEME_SPLASH_COLOR};
    box-shadow: 0 0 10px ${THEME_SPLASH_COLOR};
  }
`;

function isNumeric(numberOrString: string | number) {
  if (typeof numberOrString !== "string") return false;
  return !isNaN(numberOrString as any) && !isNaN(parseFloat(numberOrString));
}

type NumberFormProps = {
  title: string;
  units?: string;
  stateValue: any;
  setValue: (arg: any) => void;
  canBeInfinite?: boolean;
  min?: number;
  max?: number;
};
function NumberForm({
  title,
  units,
  stateValue,
  setValue,
  canBeInfinite,
  min,
  max,
}: NumberFormProps) {
  const onFormBlur = (e) => {
    let value = e.target.value;
    if (canBeInfinite) {
      if (!value || value < 1) {
        value = INFINITE;
      }
    } else if (!value) {
      value = 120; // default
    } else if (min && value < min) {
      value = min;
    } else if (max && value > max) {
      value = max;
    }
    setValue(value);
  };
  const onFormChange = (e) => {
    const value = e.target.value;
    if ((canBeInfinite && !value) || isNumeric(value)) {
      setValue(value);
    }
  };
  return (
    <Wrapper>
      <Title>{title}</Title>
      <InputWrapper>
        <Input onBlur={onFormBlur} onChange={onFormChange} value={stateValue} />
        <Units>{units}</Units>
      </InputWrapper>
    </Wrapper>
  );
}

export default memo(NumberForm);
