const initialState = {
  promptModalData: {},
  errorModalData: {},
  inputOptionsModalData: {},
};

export const MODAL_STATE_REDUCER = "modalState";

export default (state = initialState, action) => {
  const { type, payload, reducer } = action;

  if (reducer === MODAL_STATE_REDUCER) {
    return {
      ...state,
      [type]: payload,
    };
  }

  switch (type) {
    default:
      return state;
  }
};
