import { useEffect } from "react";

import { ANSWERED_STATE, LISTENING_STATE } from "../reducers/game_state";

// Prevent onkey events from firing multiple times in a row
let spaceFired = false;
let enterFired = false;
let escFired = false;
let enabled = true;

// Other escape / enter listener that will override default when enabled
let escapeEnabled = false;
let escapeTwoFired = false;

let enterEnabled = false;
let enterTwoFired = false;

export default function setupOnKeyEvents({
  gameObject,
  repeating,
  countDownSecond,
  countDownInterval,
  nextQuestion,
  onClickEnd,
}) {
  // On space bar, repeat, on enter skip/next
  useEffect(() => {
    enabled = true;
    const keyDown = async (e) => {
      if (!enabled || escapeEnabled || enterEnabled) {
        return;
      }

      // Enter pressed
      if (!enterFired && e.keyCode === 13) {
        enterFired = true;
        nextQuestion();
        // Space pressed
      } else if (!spaceFired && e.keyCode === 32) {
        spaceFired = true;
        const gameState = gameObject.getCurrentState();
        // Next question when auto-next isn't enabled
        if (gameState === ANSWERED_STATE) {
          return;
          // Repeat
        } else if (gameState === LISTENING_STATE) {
          if (!repeating && countDownSecond <= 0) {
            gameObject.onReplay();
          }
        }
      } else if (!escFired && e.keyCode === 27) {
        escFired = true;
        onClickEnd();
      }
    };
    const keyUp = (e) => {
      if (e.keyCode === 32) {
        spaceFired = false;
      } else if (e.keyCode === 13) {
        enterFired = false;
      } else if (e.keyCode === 27) {
        escFired = false;
      }
    };

    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);
    return () => {
      enabled = false;
    };
  }, [gameObject, repeating, countDownSecond, countDownInterval, nextQuestion]);
}

export function onEnterKey(onEnterFunction, isEnabled) {
  useEffect(() => {
    if (isEnabled) {
      enterEnabled = true;
    } else {
      enterEnabled = false;
    }
    const keyDown = async (e) => {
      if (!enterEnabled || !onEnterFunction || !isEnabled) {
        return;
      }

      // Enter pressed
      if (!enterTwoFired && e.keyCode === 13) {
        enterTwoFired = true;
        onEnterFunction();
      }
    };
    const keyUp = (e) => {
      if (e.keyCode === 13) {
        enterTwoFired = false;
      }
    };

    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);
    return () => {
      enterEnabled = false;
      onEnterFunction = null;
    };
  }, [onEnterFunction, isEnabled]);
}

export function onEscapeKey(onEscapeFunction, isEnabled) {
  useEffect(() => {
    if (isEnabled) {
      escapeEnabled = true;
    } else {
      escapeEnabled = false;
    }
    const keyDown = async (e) => {
      if (!escapeEnabled || !onEscapeFunction || !isEnabled) {
        return;
      }

      // Enter pressed
      if (!escapeTwoFired && e.keyCode === 27) {
        escapeTwoFired = true;
        onEscapeFunction();
      }
    };
    const keyUp = (e) => {
      if (e.keyCode === 27) {
        escapeTwoFired = false;
      }
    };

    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);
    return () => {
      escapeEnabled = false;
      onEscapeFunction = null;
    };
  }, [onEscapeFunction, isEnabled]);
}
