// - - - - - -
// Global State Constants
// - - - - - -
// Game mode and options tab options
export const NOTES = "notes";
export const INTERVALS = "intervals";
export const CHORDS = "chords";
export const MELODIES = "melodies";
export const KEYS = "keys";
export const RESULTS = "results";

// Options pages
export const GLOBAL_OPTIONS = "Global";
export const INPUT_OPTIONS = "Input";
export const OUTPUT_OPTIONS = "Output";
