import store from "../store";

export type setActionOpts = {
  setType?: string;
  setGroup?: string;
  setKey?: string;
  reset?: boolean;
  increment?: boolean;
};
export default function setAction(
  reducer: string,
  variableName: string,
  variableValue: any,
  opts?: setActionOpts
) {
  store.dispatch({
    reducer,
    type: variableName,
    payload: variableValue,
    opts: opts || {},
  });
}
