import { Note } from "@tonaljs/tonal";

import setAction from "../actions/setter";
import { MODAL_STATE_REDUCER } from "../reducers/modal_state";

export default class MidiDevice {
  onNotePlayed: (data: any) => void;
  resetInputDevice: () => void;
  midiAccess: any;
  inputs: any;
  _init: boolean;
  constructor(
    onNotePlayed: (note: string) => void,
    resetInputDevice: () => void
  ) {
    if (typeof window === "undefined") {
      return;
    }
    this.onNotePlayed = onNotePlayed;
    this.resetInputDevice = resetInputDevice;
    this.midiAccess = null;
    this._init = false;
    this.inputs = null;
  }

  async init() {
    if (this._init) {
      return;
    }
    try {
      if (!this.midiAccess) {
        this.midiAccess = await navigator.requestMIDIAccess();
      }
      this._init = true;
    } catch (error) {
      setAction(MODAL_STATE_REDUCER, "errorModalData", {
        heading: "Please enable MIDI access in browser and reload.",
        onModalPrompt: () => {
          setAction(MODAL_STATE_REDUCER, "errorModalData", {});
        },
      });
      return;
    }
  }

  destroy() {
    this.midiAccess.inputs.forEach((input: any) => {
      input.onmidimessage = null;
    });
    this.midiAccess.inputs.forEach((input: any) => {
      input.onstatechange = null;
    });
    this.midiAccess = null;
    this.onNotePlayed = null;
    this.resetInputDevice = null;
    this._init = false;
  }

  onMidiNote(event: any) {
    // Ignore on / off messages
    if (event.data.length === 1) {
      return;
    }
    if (event.data[0] === 144 && event.data[2] !== 0) {
      let note = Note.fromMidiSharps(event.data[1]);
      // We use an array because that's what we use for the microphone
      // And transpose down an octave so C3 is middle C
      note = Note.transpose(note, "-8M");
      this.onNotePlayed([note]);
    }
  }

  onMidiStateChange() {
    let inputsRemaining = 0;
    if (!this.midiAccess) {
      this.init();
      return false;
    }
    this.midiAccess.inputs.forEach(() => inputsRemaining++);
    if (inputsRemaining === 0) {
      this.resetInputDevice();
      setAction(MODAL_STATE_REDUCER, "errorModalData", {
        heading:
          "No MIDI devices available. Please insert a MIDI device and reload.",
        onModalPrompt: () => {
          setAction(MODAL_STATE_REDUCER, "errorModalData", {});
        },
      });
      return false;
    }
    return true;
  }

  async startListening() {
    if (!this._init) {
      await this.init();
    }
    if (!this.onMidiStateChange()) {
      return false;
    }
    this.midiAccess.inputs.forEach((input: any) => {
      input.onmidimessage = this.onMidiNote.bind(this);
    });
    this.midiAccess.inputs.forEach((input: any) => {
      input.onstatechange = this.onMidiStateChange.bind(this);
    });
    return true;
  }
}
